<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Kurs/modul i korrekt kildehenvisning bør være obligatorisk.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Korona har forårsaket at universitetene måtte begynne å benytte hjemmeeksamen. Dette åpnet da opp
              muligheten til å kildehenvise til annet lese materiale enn før. Det viser seg at jukstallene under
              pandemiene økte.
            </p>
            <p>
              En av de største årsakene til juks var plagiat og feil kildehenvisning. Spørsmålet dreier seg da om alle
              studenter ved UiA må ha fullført en obligatorisk kurs/modul i kildehenvisning for å gå opp til eksamen.
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>